import { axiosCall } from "./ec2-api-lib";

export const easyposProxy = async ({ apiName, body }) => {
  let path = `/pos/easypos?apiName=${apiName}`;
  path += '&mode=debug';

  return await axiosCall({ method: "POST", path, body });
}

export const easyposCopyImage = async ({ body }) => {
  // body = { shopId, imageUrl }
  let path = `/pos/easypos/copy-image`;

  return await axiosCall({ method: "POST", path, body });
}