const orderResult = (props) => {
  const { res, rdxInfo, } = props;
  
  const msg = rdxInfo.systemMsg.dialog;
  const codeMapping = {
    'S00000': { status: true, title: msg.orderSuccess, body: msg.orderComplete },
    'E00038': { status: false, title: msg.warnning, body: msg.tableLocked },
    'E00026': { status: false, title: msg.warnning, body: msg.posDisconnect },
  };
  const aliasMapping = {
    'Table Locked' : 'E00038',
    'DEF_CODE_USING_TABLE' : 'E00038',
    'DEF_CODE_SUCCESS' : 'S00000',
    '9005 - Pos Program is not running at orderType NEW' : 'E00026',
    'Error: POS가 꺼져 있습니다!' : 'E00026'
  }

  let resultMessage = {
    status: false,
    title: '',
    body: ''
  }
  // }else if(codeMapping[res.result.CODE]){
  //   resultMessage = codeMapping[res.result.CODE];
  // }

  if(res.status === false) {
    resultMessage = {
      status: false,
      title: msg.warnning,
      body: msg.jwtError,
    }
  }else if(res.result.CODE) {
    const normalized = aliasMapping[res.result.CODE] || res.result.CODE;
    resultMessage = codeMapping[normalized];
  }else {  // status가 true 이지만 코드가 없는경우 처리
    resultMessage = {
      status: false,
      title: msg.warnning,
      body: msg.orderFail,
    }
  }

  return resultMessage;
}

export default orderResult;


// const orderResult = (props) => {
//   const { res, rdxInfo, } = props;
  
//   const msg = rdxInfo.systemMsg.dialog;
//   const codeMapping = {
//     'S00000': { status: true, title: msg.orderSuccess, body: msg.orderComplete },
//     'E00038': { status: false, title: msg.warnning, body: msg.tableLocked },
//     'E00026': { status: false, title: msg.warnning, body: msg.posDisconnect },
//   };
//   const aliasMapping = {
//     'Table Locked' : 'E00038',
//     'DEF_CODE_USING_TABLE' : 'E00038',
//     'DEF_CODE_SUCCESS' : 'S00000',
//     '9005 - Pos Program is not running at orderType NEW' : 'E00026'
//   }
//   let resultMessage = {
//     status: false,
//     title: '',
//     body: ''
//   }
//   if(res.status === false) {
//     resultMessage = {
//       status: false,
//       title: msg.warnning,
//       body: msg.jwtError,
//     }
//   }else if(codeMapping[res.result.CODE]){
//     resultMessage = codeMapping[res.result.CODE];
//   }else {  // status가 true 이지만 코드가 없는경우 처리
//     resultMessage = {
//       status: false,
//       title: msg.warnning,
//       body: msg.orderFail,
//     }
//   }

//   return resultMessage;
// }
