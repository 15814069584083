
import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { ReactComponent as Delete } from '../../../assets/icons/recycle.svg';
import { makeItemName, thousandsSeperator } from '../../../lib/utils';
import { removeCartItems, updateItemCount } from '../../../redux/cart';

import Price from '../../../components/Price';
import Counter from '../../../components/Counter';
import constants from '../../../settings/constants';

import './BodySection.css';

const CartItem = (props) => {
  const { rdxInfo, cartItems } = props;
  const { setOpenCartPage } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if(!cartItems.length)
      setOpenCartPage(false);
  }, [cartItems.length]);

  const handleClosed = (item) => {
    dispatch(removeCartItems({itemName: item.itemName}))
  }

  const setCount = (item, newCount) => {
    dispatch(updateItemCount({itemName: item.itemName, count: newCount}));
  }
  return(
    <div className="CartItem">
      { cartItems.map((item, idx) => {
        const name = makeItemName(item);
        return(
          <div className='items' key={idx}>
            <div className='name-close'>
              <div>
                <div className='main-name'>{name.main}{' (₩'+thousandsSeperator(item.price-item.optionPrice)+')'}</div>
                <div className='option-name'>{name.option}</div>
              </div>
              <div onClick={()=>handleClosed(item)}>
                <Delete width={constants.sIcon - 5} />
              </div>
            </div>
            <div className='cart-counter-button'>
              <Counter count={item.count} setCount={(newCount) => setCount(item, newCount)}/>
            </div>
            <div className='price-info'>
              <TotalPrice item={item} systemMsg={rdxInfo.systemMsg} />
            </div>
            <div className={idx + 1 === cartItems.length ? '' : 'counter-modify'} />
          </div>
        )
      })}
    </div>
  )
}

function TotalPrice({item, systemMsg}) {
  
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateItemCount({ itemName: item.itemName, count: item.count }));
  }, [item.count]);

  return(
    <Price price={item.payAmount} lang={systemMsg} type='cart' />
  )
}

export default CartItem;