import { useEffect, useState } from "react";

import excute from "../../data/pos/excute";
import constants from "../../../settings/constants";
import IsEssential from '../../../components/IsEssential';

import Extra from "./Extra";
import Essential from "./Essential";

import './BodySection.css';

const OptionInfo = (props) => {
  const { item, rdxInfo } = props;
  const { setSame, setHasOption } = props;

  const [selectedCount, setSelectedCount] = useState(0);
  const [optionCount, setOptionCount] = useState(0);
  const [essGroupCount, setEssGroupCount] = useState(0);


  const [choiceItems, setChoiceItems] = useState([]);
  const [choiceGroups, setChoiceGroups] = useState([]);
  const [optionGroups, setOptionGroups] = useState([]);

  const [isEssential, setEssential] = useState(true);
  
  const posName = rdxInfo.shopInfo.posName;
  const optionProps = {
    ...props,
    optionCount, choiceGroups,
    setOptionCount, setSelectedCount,
    setSame,
  }
  useEffect(() => {
    const type = 'Options';
    const result = excute({ item, rdxInfo, posName, type });
    
    setChoiceGroups(result.choiceGrps);
    setChoiceItems(result.choiceItems);
    setHasOption(result.choiceItems?.length ? true : false);
  }, [rdxInfo]);

  useEffect(() => {
    let sum = 0;
    choiceGroups.forEach(grp => sum = sum + grp.SetCnt);

    if(!sum) {
      setSame(false);
    }
    if(choiceItems < 1) {
      setSame(false);
      setEssential(false);
    }
  }, [choiceGroups, choiceItems]);

  useEffect(() => {
    const result = choiceGroups.filter(grp => 
      (grp.subCol1 === constants.ess) || (grp.subCol1 === grp.required)
    );
    let total = 0;

    result.forEach(grp => grp.SetCnt > 1 && (total = total + 1));
    result.length ? setEssGroupCount(result.length - total) : setEssential(false);
  }, [choiceGroups]);

  useEffect(() => {
    if((essGroupCount !== 0) && (selectedCount === essGroupCount)) {
      setEssential(false);
    }
  }, [selectedCount, essGroupCount]);

  useEffect(() => {
    const type = 'EssExt';
    const ess = choiceGroups.filter(grp => {
      const unionEss = (grp.subCol1 === constants.ess) || grp.required;
      const okEss = (grp.SDA_CLS_NM !== '') && (grp.SDA_CLS_NM !== undefined);
      const easyEss = (grp.ESSENTIAL_YN === 'Y') && (parseInt(grp.MAX_SELECT_QTY) < 2);

      return unionEss || okEss || easyEss;
    });

    let ext;
    switch(posName) {
      case 'UNIONPOS':
      case 'OKPOS':
      case 'OASIS_POS':
        ext = choiceGroups.filter(grp => {
          const unionExt = ((grp.subCol1 !== constants.ess) || (grp.subCol1 === undefined)) && !grp.required;
          const okExt = (grp.SDA_CLS_NM === '') || (grp.SDA_CLS_NM === undefined);
          return unionExt && okExt;
        });
        break;
      case 'EASY_POS':
        ext = choiceGroups.filter(grp => {
          const easyExt = (grp.ESSENTIAL_YN === 'N') || ((grp.ESSENTIAL_YN === 'Y') && (grp.MAX_SELECT_QTY > 1));
          return easyExt
        });
        break;
    }
    
    // Extra 옵션중에 수량제한이 있는 옵션 우선순위 높이기.
    
    if(rdxInfo.shopInfo.posName === 'UNIONPOS' || rdxInfo.shopInfo.posName === 'EASY_POS') {
      ext = [ ...ext.filter(grp => grp.SetCnt > 0), ...ext.filter(grp => grp.SetCnt < 1)];
    }

    // const sortExt = [
    //   ...ext.filter(grp => grp.SetCnt > 0), ...ext.filter(grp => grp.SetCnt < 1 || !grp.required || grp.required === false)
    // ];

    // 토리엔비어 전용
    ext.forEach(item => {
      if(item.itemName2 === constants.tori) {
        item.SetCnt = 0;
        item.subCol1 = '';
      }
    });
    
    if(!ext.length)
      setSame(false);
    
    excute({ ess, ext: ext, choiceItems, posName, type });
    
    setOptionGroups([ ...ess, ...ext ]);
  }, [choiceGroups, choiceItems]);

  return(
    <div className="OptionInfo">
      { optionGroups.map((grp, idx) => {
        const isEasyEssential = (grp.ESSENTIAL_YN === 'Y') && (parseInt(grp.MAX_SELECT_QTY) < 2);
        const isEssential = 
          (grp.subCol1?.includes(constants.ess) && grp.SetCnt < 2) || grp.required || grp.SDA_CLS_CD || isEasyEssential;
        
        return(
          <div className={grp.optionItems?.length ? "grp-section" : ''} key={idx}>
            { grp.optionItems.length
              ? <div className="grp-name-section"> {/*className={`grp-name-section ${isEssential ? 'grp-name-ess' : 'grp-name-ext'}`} */}
                  <div>{grp.itemName2}</div>
                  <IsEssential setCnt={grp.SetCnt} ess={isEssential} sysMsg={rdxInfo.systemMsg}/>
                </div>
              : null
            }
            <div className={grp.optionItems.length ? '' : 'grp-option-section'}>
              { isEssential && grp.optionItems.length
                ? <Essential optionItems={grp?.optionItems} { ...optionProps } />
                : <Extra optionItems={grp?.optionItems} currentGroup={grp} { ...optionProps} />
              }
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default OptionInfo;