const posMap = {
  UNIONPOS(options, choiceItems) {
    union(options, choiceItems);
  },
  OKPOS(options, choiceItems) {
    ok(options, choiceItems);
  },
  OASIS_POS(options, choiceItems) {
    oasis(options, choiceItems);
  },
  EASY_POS(options, choiceItems) {
    easy(options, choiceItems);
  }
}

const union = (options, choiceItems) => {
  options.forEach(grp => {
    grp.optionItems = [];
    // choiceItems?.forEach(item => {
    //   if((item.MenuCode === grp.menuCode) && item.MenuCode !== undefined && !item.isSoldout && !item.removed)
    //     grp.optionItems.push({ ...item, Qty: 0 });  // option의 개수를 만들기 위한 초기화
    // });
    emptyItemList(choiceItems, grp);
    grp.optionItems.sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });
  });
}

const ok = (options, choiceItems) => {
  
  options?.forEach(grp => {
    grp.optionItems = [];
    if(grp.itemList?.length) {
      grp.itemList?.forEach(opt => {
        choiceItems?.forEach(item => {
          if((item.itemCode === opt) && item.MenuCode !== undefined)  // onl option 일때 opt와 item.itemCode가 같으면
            grp.optionItems.push({ ...item, Qty: 0 })                 // 해당 item이 속해 있는 카테고리에서 솔드아웃
        })                                                            // 이거나 삭제 되었어도 옵션엔 노출 댐.
      })
    }else {
      emptyItemList(choiceItems, grp);
    }
  })
}

const oasis = (options, choiceItems) => {
  options.forEach(grp => {
    grp.optionItems = [];
    emptyItemList_oasis(choiceItems, grp);
    grp.optionItems.sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });
  });
}

const easy = (options, choiceItems) => {
  options.forEach(grp => {
    grp.optionItems = [];
    emptyItemList_easy(choiceItems, grp);
    grp.optionItems.sort((a, b) => a.sortOrder - b.sortOrder)
  })
}

const makeOptionItems = (options, choiceItems, posName) => {
  posMap[posName](options, choiceItems);
}

function emptyItemList(items, grp) {
  
  items.forEach(item => {
    if((item.MenuCode === grp.menuCode) && item.MenuCode !== undefined && !item.isSoldout && !item.removed)
      grp.optionItems.push({ ...item, Qty: 0 });
  });
}

function emptyItemList_oasis(items, grp) {
  items.forEach(item => {
    if((item.settype === grp.settype) && item.MenuCode !== undefined && !item.isSoldout)
      grp.optionItems.push({ ...item, Qty: 0 });
  })
}

function emptyItemList_easy(items, grp) {
  items.forEach(item => {
    if((item.ORDER_CLASS_CODE === grp.ORDER_CLASS_CODE) && !item.isSoldout)
      grp.optionItems.push({ 
        ...item,
        itemName2: item.ITEM_NAME,
        itemCode: item.ITEM_CODE,
        price: parseInt(item.ITEM_PRICE),
        Qty: 0, 
        sortOrder: parseInt(item.DISPLAY_ORDER) 
      });
  })
}

export default makeOptionItems;