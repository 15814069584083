import dayjs from "dayjs"
import lzString from 'lz-string';

export const checkServiceTime = (serviceTime) => {
  const dayName = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  const now = dayjs();
  const day = now.get('day');
  const hour = now.get('hour');
  const start = parseInt(serviceTime.startTime);
  let end = parseInt(serviceTime.endTime);
  if(end === 0)
    end = 24;

  const result = serviceTime.days?.includes(dayName[day]);

  if(!result)
    return false;
  else {
    if(start === end)
      return true;
    else if(end < start) {
      if((hour >= start) || (hour < end))
        return true;
      else
        return false;
    } else {
      if((hour >= start) && (hour < end))
        return true;
      else
        return false;
    }
  }
}

export function grpCheckServiceTime(grpList) {
  grpList.forEach(grp => {
    if(grp.serviceTime !== undefined)
      grp.isService = checkServiceTime(grp.serviceTime);
  });
}

export function usePosLang(item, lang) {
  if(item.nameLang === undefined)
    item.nameLang = {}
  if(item.posNameLang) {
    if(item.posNameLang[lang] !== undefined && item.posNameLang[lang] !== '') {
      if(item.nameLang[lang] === '' || item.nameLang[lang] === undefined){
        item.nameLang[lang] = item.posNameLang[lang]
      }
    }
  }
}

export function sorting(items) {
  let sorted = [ ...items ];
  
  if(!sorted.length)
    return items;

  sorted.sort((a, b) => {
    if(a.sortOrder > b.sortOrder) return 1;
    else if(a.sortOrder < b.sortOrder) return -1;
    else if(a.sortOrder === b.sortOrder) return 0;
  })

  return sorted;
}

export function usingPosName(list) {
  list.GrpList.map((grp) => {
    if(list.menuSettings?.usePosMenuFirst) {
      grp.displayName =grp.name;
      grp.backupName = grp.name;
    }else {
      grp.backupName = grp.displayName;
    }
  });
}

export function parseIf(value) {
  return value ? JSON.parse(value) : value;
}

export function isNum(str) {
  return /^[+-]?\d+$/.test(str);
}

export function sortTableList(tbList) {
  let numTable = tbList.filter(tb => isNum(tb.TableName));
  let nonNumTable = tbList.filter(tb => !isNum(tb.TableName));

  numTable.sort((a, b) => {
    if(parseInt(a.TableName) < parseInt(b.TableName))
      return -1;
    else if(parseInt(a.TableName) > parseInt(b.TableName))
      return 1;
    else 
      return 0;
  });
  
  nonNumTable.sort((a, b) => {
    if(a.TableName < b.TableName)
      return -1;
    else if(a.TableName > b.TableName)
      return 1;
    else
      return 0;
  });
  tbList = [ ...numTable, ...nonNumTable ];
  
  return [ ...numTable, ...nonNumTable ];
}

export function setName(usePos, item) {
  const nameTouse = usePos ? item.itemName : item.itemName2;
  item.backupName = nameTouse;
  item.itemName2 = nameTouse;
}

export function setSortInfo(grpList, item, posName) {
  if(!grpList || !item)
    return;
  
  item.sortInfo = [];
  switch(posName) {
    case 'OKPOS': {
      const temp = grpList.filter(grp => item.tuClassList?.includes(grp.GrpCode));
      item.groupCode = item.tuClassList;
      temp?.forEach((grp) => pushSortInfo(grp, item));

      break;
    }
    case 'EASY_POS' :
    case 'OASIS_POS' :
    case 'UNIONPOS' : {
      grpList.forEach((grp) => {
        if(Number.isNaN(parseInt(grp.GrpCode) || parseInt(grp.gcode) || parseInt(grp.groupCode))) {
          if((grp.GrpCode || grp.gcode || grp.groupCode) === item.groupCode){
            pushSortInfo(grp, item);
          }
        }else {
          if(parseInt(grp.GrpCode || grp.gcode || grp.groupCode) === parseInt(item.groupCode))
            pushSortInfo(grp, item);
        }
      })
      break;
    }
  }
}
function pushSortInfo(grp, item) {
  item.sortInfo.push({
    name: grp.displayName,
    grpCode: grp.GrpCode || grp.groupCode,
    grpSortOrder: grp.sortOrder,
    sortOrder: item.sortOrder
  })
}

export function setBigImageRect({width, height}) {
  const rect = {
    width: 0,
    height: 0,
  }
  rect.width = width; //width < 484 ? width : 484;
  rect.height = height - (height * 0.5);
  // rect.height = height - (height * (450 / 692));
  return rect;
}

export function optimizingUrl(url) {
  let opti = url.replace(/\s/g, "%20");
  opti = opti.replace(/\(/g, "%28");
  opti = opti.replace(/\)/g, "%29");
  
  return opti;
  /** 기존 방법
   * let opti = url.split(' ').join('%20');  // 문자열에 띄어쓰기가 있을경우 기호문자로 대체해줌.
   * opti = opti.replace(/\(/g, "\\(");  // 문자열에 ( 가 있을 경우 정규식으로 대체
   * opti = opti.replace(/\)/g, "\\)");  // 문자열에 ) 가 있을 경우 정규식으로 대체
   */
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

export function makeThumbnailPath(url, shopId) {
  const arTemp = url.split(shopId);
  let tnUrl = '';
  if(arTemp[1] === undefined)
    tnUrl = arTemp[0] + shopId + '/tn/' + '';
  else
    tnUrl = arTemp[0] + shopId + '/tn' + arTemp[1];
  
  return tnUrl;
}

export function bnh(items) {
  if (!items) return;

  // 우선순위 정렬 기준을 설정
  const sortPriority = (item) => {
    return [
      item?.isBest === true ? 0 : 1,       // isBest: true가 우선
      item?.isHot === true ? 0 : 1,        // isHot: true가 우선
      item?.isNew === true ? 0 : 1,        // isNew: true가 우선
      item?.isSoldout === false ? 0 : 1,   // isSoldout: false가 우선
    ];
  };

  // 우선순위에 따라 정렬
  return items.slice().sort((a, b) => {
    const priorityA = sortPriority(a);
    const priorityB = sortPriority(b);

    // 각 조건을 차례대로 비교
    for (let i = 0; i < priorityA.length; i++) {
      if (priorityA[i] !== priorityB[i]) {
        return priorityA[i] - priorityB[i];
      }
    }
    return 0; // 모든 조건이 동일한 경우
  });
}

export function thousandsSeperator(value) {
  if(isNaN(parseInt(value)))
    return false;

  const price = Number.isInteger(value) ? value : parseInt(value);
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function pushItem(list, item) {
  if(!list.some(it => it === item)) {
    list.push(item);
  }

  return list;
}

export function makeOptionInfo(list) {
  let fullName = '';
  let optionPrice = 0;
  
  list?.forEach(opt => {
    fullName += ', ' + opt.itemName2;
    opt.Price = opt.choiceInfo?.Price * opt.Qty;
    // opt.Price = opt.choiceInfo?.Price;
    optionPrice += opt.Price;
  });
  fullName = '(' + fullName.substring(2) + ')';
  
  return { fullName, optionPrice };
}

export function makeItemName(item) {
  const main = item.itemName.split('#onl')[0];
  let option = '';

  if(item.options.length) {
    item.options.forEach(opt => {
      // const price = opt.Qty * item.count * opt.Price || opt.price;
      const price = item.count * opt.Price || opt.price;
      option = 
        option + '- ' + opt.itemName2 + ' \u00D7 ' + 
        opt.Qty * item.count + ' (₩' + thousandsSeperator(price) + ')' + '\n';
    });
  }
  return { main, option }
}

export async function sortCartItems(items) {
  let temp;
  const tItems = Object.assign([], items);
  temp = tItems.map(item => {
    return item = { ...item, grpSortOrder: item.sortInfo[0].grpSortOrder }
  });

  temp.sort((a, b) => {
    return a.grpSortOrder - b.grpSortOrder;
  });

  return temp;
}

export function makeNewline(text) {
  let name = '';
  if(text.length > 3) {
    const arName = text.split(' ');
    arName.forEach(item => name = name + item + '\n')
  }else
    name = text;

  let temp;
  if(name.length > 10) {
    temp = name.slice(0,11) + '...';
    if(temp.length > name.length)
      temp = name;
  }else
    temp = name;
  
  return temp;
}

export async function getIp() {
  try {
    const res = await fetch('https://api64.ipify.org?format=json');
    const data = await res.json();
    return data.ip;  // 성공적으로 데이터를 받아오면 IP 반환
  } catch (error) {
    console.error('Error getIp: ', error);
    return null;  // 에러 발생 시 null 반환
  }
}

export function strDecompress(comp) {
  return lzString.decompressFromUTF16(comp);
}