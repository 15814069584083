import { easyposProxy } from "../../../lib/easy-api-lib";
import { checkServiceTime, grpCheckServiceTime, isEmptyObject, parseIf, setName } from "../../../lib/utils";
import { setSortInfo, sorting, usePosLang, usingPosName, bnh, makeOptionInfo } from "../../../lib/utils";
import { supportedLangs } from "../../../settings/langs";
import makeOptionItems from "./make-option-items";

const posMap = {
  ItemInfo(props) {
    const res = itemInfo(props);
    return res;
  },
  ItemList(props) {
    const res = itemList(props);
    return res;
  },
  MenuList(props) {
    const res = menuList(props);
    return res;
  },
  Options(props) {
    const res = makeOption(props);
    return res;
  },
  EssExt(props) {
    const res = makeEssExt(props);
    return res;
  },
  CartItems(props) {
    const res = makeCartItems(props);
    return res;
  },
  OrderList(props) {
    const res = orderList(props);
    return res;
  }
}

const itemInfo = (props) => {
  const { itemInfo, urlInfo } = props;
  const keysToParse = [
    'itemOrderClassList', 'orderList', 'subItemList', 'touchClassList', 'tableList',
    'tableGroupList', 'menuSettings', 'tableCallList', 'orderClassList'
  ];

  /**parsing Datas */
  keysToParse.forEach(key => {
    itemInfo[key] = parseIf(itemInfo[key]);
  });

  /** make Grp, DisplayGrpList */
  itemInfo.GrpList = itemInfo.touchClassList;
  mappingGrp(itemInfo);                         // grp 속성 재설정을 위해 DsiplayGrpList 생성 전에 호출
  itemInfo.DisplayGrpList = itemInfo.touchClassList.filter(grp => !grp.hidden);

  /** check service time */
  grpCheckServiceTime(itemInfo.DisplayGrpList);

  /** sorting DisplayGrpList */
  itemInfo.DisplayGrpList = sorting(itemInfo.DisplayGrpList);

  /** pos 메뉴명 사용여부 결정 */
  usingPosName(itemInfo);

  /** pos 다국어 메뉴명 사용 */
  itemInfo.DisplayGrpList.map((grp) => {
    supportedLangs.forEach(lang => usePosLang(grp, lang));
  })

  /** TableInfo 및 TableList 설정 */
  /**tableList의 각 테이블에 tableGroupCode과 tableGroupName을 적용. */
  const tableGroupMap = new Map(
    itemInfo.tableGroupList.map(tbGrp => [tbGrp.TABLE_GROUP_CODE, tbGrp.TABLE_GROUP_NAME])
  );

  itemInfo.tableList.forEach(tbList => {
    if (tableGroupMap.has(tbList.TABLE_GROUP_CODE)) {
      tbList.tableGroupName = tableGroupMap.get(tbList.TABLE_GROUP_CODE);
    }
  });
  
  mappingTable(itemInfo);
  itemInfo.TableInfo = itemInfo.tableList.find(
    tbList => parseInt(tbList.tableCode) === parseInt(urlInfo.tableNo)
  );

  return itemInfo;
}

const itemList = (props) => {
  const { itemList, itemInfo } = props;
  const keysToParse = [
    'nameLang', 'serviceTime', 'descriptionLang', 'posNameLang', 'posDescriptionLang'
  ];
  itemList.map(item => {
    keysToParse.forEach(key => item[key] = parseIf(item[key]));
    
    // item posname 설정
    setName(itemInfo.menuSettings?.usePosMenuFirst, item);
    
    if(item.serviceTime)
      item.isService = checkServiceTime(item.serviceTime);

    if(!item.nameLang)
      item.nameLang = {}
    /**SortInfo */
    setSortInfo(itemInfo.GrpList, item, itemInfo.optionName.toUpperCase());
  })
  
  return itemList;
}

const menuList = (props) => {
  const { itemInfo, itemList } = props;

  itemInfo.GrpList.forEach(grp => {
    grp.menus = itemList?.filter(item => item.groupCode?.includes(grp.groupCode));
    grp.menus = sorting(grp.menus);
    grp.menus = bnh(grp.menus);
  });

  itemInfo.ChoiceItemList = itemInfo.orderList;
}

const makeOption = (props) => {
  /**
   * orderClassList, orderList를 리턴.
   */
  const { item, rdxInfo } = props;
  let choiceGrps = [];
  let choiceItems = [];
  const arTemp = [];
  let optItem;

  const setMenus = rdxInfo.itemInfo.itemOrderClassList;
  const optGrps = setMenus.filter(set => set.ITEM_CODE === item.itemCode);
  
  choiceGrps = rdxInfo.itemInfo.orderClassList;
  choiceGrps.forEach(grp => {
    rdxInfo.itemInfo.ChoiceItemList.forEach(item => {
      if(grp.ORDER_CLASS_CODE === item.ORDER_CLASS_CODE) {
        optItem = { 
          ...item, 
          choiceInfo: {
            ItemCode: item.ITEM_CODE,
            Price: parseInt(item.ITEM_PRICE),
            Qty: 0,
            SerNo: 1,
            SetCnt: 0,
          }
        }
        !isEmptyObject(optItem) && choiceItems.push(optItem);
      }
    })
  });
  
  choiceGrps.forEach(grp => {
    optGrps.forEach(optGrp => {
      if(grp.ORDER_CLASS_CODE === optGrp.ORDER_CLASS_CODE) {
        const tempGrp = { 
          ...grp,
          itemName2: grp.ORDER_CLASS_NAME,
          groupCode: grp.ORDER_CLASS_CODE,
          selected: 0,
          isSame: true,
          SetCnt: parseInt(grp.MAX_SELECT_QTY),
        }
        arTemp.push(tempGrp);
      }
    })
  });
  
  return { choiceGrps: arTemp, choiceItems }
}

const makeEssExt = (props) => {
  const { ess, ext, choiceItems, posName } = props;
  
  makeOptionItems(ess, choiceItems, posName);
  makeOptionItems(ext, choiceItems, posName);
}

const makeCartItems = (props) => {
  const { item, checkedList } = props;
  const optionInfo = makeOptionInfo(checkedList);
  const fullName = item.itemName2 + '#onl' + optionInfo.fullName;
  const options = Array.from(checkedList);
  const sortOrder = item.sortInfo.find(info => info.grpCode === item.groupCode)?.grpSortOrder;
  const _item = {
    defaultItemName: item.itemName,
    itemName: fullName,
    itemCode: item.itemCode,
    desc: item.description,
    best: item.isBest,
    hot: item.isHot,
    new: item.isNew,
    grpSortOrder: sortOrder,
    itemGroup: item.groupCode,
    soldOut: item.isSoldout,
    image: item.image,
    price: parseInt(item.price) + optionInfo.optionPrice,
    count: props.itemCount,
    optionPrice: optionInfo.optionPrice,
    payAmount: parseInt(item.price) + optionInfo.optionPrice,
    sortInfo: item.sortInfo,
    options,
  }
  checkedList.clear();

  return _item;
}

const orderList = async (props) => {
  const { rdxInfo } = props;
  const { setLoading } = props;

  try {
    const tableInfoBody = {
      tableGroupCode: rdxInfo.itemInfo.TableInfo.TABLE_GROUP_CODE,
      tableCode: rdxInfo.itemInfo.TableInfo.tableCode,
      shopNo: rdxInfo.shopInfo.storeCode
    }
    
    let res = await easyposProxy({ apiName: 'tableOrderInfo', body: tableInfoBody });

    if(!res.status) throw new Error('order list call error!');

    let mainItem = res.result.body.itemList.filter(item => item.parentDetailNo === '0000');
    let optItem = res.result.body.itemList.filter(item => item.parentDetailNo !== '0000');
    let mainSum = 0;
    let optSum = 0;
    let totalSum = 0;

    mainItem.map(item => {
      const temp = optItem.filter(opt => opt.parentDetailNo === item.detailNo);
      optSum = 0;
      temp.forEach(opt => {
        opt.ItemName = 
          rdxInfo.itemInfo.ChoiceItemList.find(item => item.ITEM_CODE === opt.itemCode).ITEM_NAME;
        opt.Qty = opt.qty;
        opt.TotalAmt = parseInt(opt.saleAmt);
        optSum = optSum + parseInt(opt.saleAmt);
      });
      item.Qty = parseInt(item.qty);
      item.ItemName = rdxInfo.itemList.find(it => it.itemCode === item.itemCode).itemName2;
      item.options = temp;
      item.optionPrice = optSum;
      item.TotalAmt = parseInt(item.saleAmt);
      mainSum = mainSum + parseInt(item.saleAmt);
    });
    mainItem.forEach(item => {
      totalSum = totalSum + item.TotalAmt + item.optionPrice;
    })
    
    return {
      orders: mainItem,
      status: res.status,
      amount: totalSum,
    }
  }catch(e) {
    console.log('Call order list error: ', e.message);
  }finally { setLoading(false) }
}

function mappingGrp(itemInfo) {
  itemInfo.GrpList.map((grp) => {
    grp.name = grp.groupName;
  });
  itemInfo.orderClassList.map(grp => {
    if((grp.MAX_SELECT_QTY === '1')){
      grp.MAX_SELECT_QTY = '0';
    }
  })
}

function mappingTable(itemInfo) {
  itemInfo.tableList.map((tb) => {
    return tb.TableName = tb.tableName;
  })
}
export default function excuteEasy(props) {
  return posMap[props.type](props);
}